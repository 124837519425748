import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/articulo2.png";
import noticias9 from "../../../../static/img/blog/noticias-09-.png";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='¿Qué se debe considerar en un buen sitio web de RI?'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias9}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                19 Nov. 2020
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : J.A Lovera
                </span>
                                <span>
                    Herramientas de RI
                </span>
                            </div>
                        </div>

                        <h2>¿Qué se debe considerar en un buen sitio web de RI?</h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                    <p>Los mejores sitios web de Relación con Inversionistas son los que proporcionan a los inversores
                        un contexto convincente e informativo
                        que les permita comprender con claridad el negocio y la situación de la empresa, creando
                        confianza e influyendo positivamente en sus decisiones
                        de inversión.</p>

                    <p>De acuerdo con Rivel Research, 75% de los inversores institucionales y analistas utilizan
                        semanalmente los sitios web de
                        RI de las empresas en las que poseen participación o están
                        considerando invertir. Por otro lado, un reporte de Cision PRNewswire señaló que más del 53% de
                        los inversores no invertirán en una empresa que no cuenta con un sitio web de RI. </p>

                    <p>En este sentido, tener un sitio web de RI eficaz ayuda a generar un interés cuantificable y a
                        crear una base de accionistas
                        activos. Esto se logra a través de una buena entrega y ejecución efectiva de
                        comunicaciones dirigidas a los inversionistas. Dentro de las categorías de mejores prácticas que
                        un
                        sitio web de RI debe manejar, se encuentran: </p>

                    <p><b><em>Uso intuitivo</em></b></p>

                    <p>Los sitios web de RI deben incluir un lenguaje convincente e interesante, de tal forma que un
                        inversor quiera visitarlo y
                        pueda navegar fácilmente, identificando todos los elementos esenciales como la
                        narrativa, los objetivos y la visión de la empresa. Estos elementos para el uso intuitivo
                        incluyen menos
                        opciones de menú, menos texto, y más opciones visuales que guíen al usuario a las distintas
                        herramientas en el sitio. </p>

                    <p>Asimismo, el contenido presentado en los sitios web debe estar bien organizado y presentado de
                        manera visualmente
                        atractiva (no limitado a la publicación de información en archivos PDF). Es por
                        ello que se debe aprovechar al máximo el potencial interactivo de los sitios web, ofreciendo
                        comentarios más completos,
                        al igual que un contenido multimedia.</p>

                    <p><b><em>Experiencia móvil </em></b></p>

                    <p>En los últimos años, la visualización móvil ha superado a la visualización de escritorio, lo que
                        significa que a
                        medida que se siga consumiendo más contenido en los teléfonos celulares, los sitios webs deberán
                        cambiar y con ello,
                        también las plataformas de RI. Una manera de mejorar la experiencia
                        móvil es priorizar el contenido más visualizado; por ejemplo, si de acuerdo con algún análisis
                        del sitio web se
                        observó que los usuarios móviles revisan con mayor frecuencia los eventos relevantes y los
                        reportes financieros, se puede priorizar el desarrollo de estas áreas por encima de otras del
                        sitio. </p>

                    <p><b><em>Información y estrategia de la empresa </em></b></p>

                    <p>La información y estrategia de la compañía son secciones fundamentales en un sitio web de RI, ya
                        que constituyen
                        la narración de su historia corporativa. Con el propósito de captar la atención de
                        potenciales inversores, estas secciones deben destacar los valores, las metas y los logros de la
                        compañía de forma creativa, asegurándose de que la marca e historia de la empresa se
                        ejemplifiquen de una manera atractiva y concisa.</p>

                    <p>Entre las recomendaciones de las mejores empresas se incluye: proveer información acera de los
                        integrantes que
                        conforman el equipo de RI, incluir una sección “acerca de nosotros” centrada en el ángulo
                        financiero y de inversión
                        de la empresa, una carta del CEO o CFO dirigida a los inversores,
                        videos e infografías.</p>

                    <p><b><em>Acceso a información financiera </em></b></p>

                    <p>Proporcionar acceso a la información financiera es algo más que limitarse a cumplir con la
                        normativa,
                        la información financiera permite trazar un mapa de los logros y objetivos para proporcionar a
                        los
                        inversores un contexto vital que les pueda ayudar a tomar decisiones de inversión en la
                        compañía.
                        En este sentido, mientras más información financiera interactiva y de fácil acceso se
                        proporcione, la empresa
                        será más atractiva para los inversionistas.</p>

                    <p>Entre las prácticas para mejorar la comunicación con los inversores, mediante la disponibilidad
                        de la información,
                        se encuentran: contar con material complementario sobre ganancias (webcast,
                        presentaciones, etc.), comunicados de prensa, informes anuales y trimestrales, datos bursátiles
                        históricos,
                        precio de la acción, centro interactivo de analistas, declaraciones reglamentarias y
                        documentos de gobernanza empresarial. </p>

                    <p>Es importante asegurarse de que los archivos cuenten con un acceso rápido y fácil, incluir las
                        transcripciones,
                        los documentos de las finanzas en Excel, un video corto del CEO o CFO comentando
                        los resultados publicados e incluir hipervínculos en los informes PDF, en caso de ser
                        necesario. </p>

                    <p><b><em>Responsabilidad Social Corporativa </em></b></p>

                    <p>Las iniciativas de Responsabilidad Social Corporativa (RSC) o Inversión Socialmente Responsable
                        se están
                        convirtiendo rápidamente en un estándar para empresas públicas de todo el mundo y de todos
                        los sectores. En los últimos años, se ha observado un crecimiento importante en el interés de
                        clientes
                        e inversionistas de que las empresas públicas tengan un impacto social o ambiental positivo, más
                        allá de
                        sus objetivos comerciales corporativos. </p>

                    <p>El nivel de cumplimiento de estas expectativas se ve reflejado en los ingresos de las empresas,
                        por lo
                        que termina influyendo en las decisiones de los inversionistas. De acuerdo con el informe del
                        2018
                        realizado por el Foro para la Inversión Sostenible y Responsable de EE. UU. (US SIF en inglés)
                        sobre
                        tendencias de inversión, el 26% del total de capital bajo administración profesional en EE. UU.
                        fue invertido
                        usando estrategias de Inversión Socialmente Responsables. </p>

                    <p>Las RSCs demuestran los valores, objetivos y posición de las empresas en el mercado, los cuales
                        son aspectos
                        fundamentales que los inversionistas consideran al evaluar el potencial de ingresos futuros.
                        Un ejemplo es Facebook, que incluye en la página de inicio de su sitio web un botón de
                        “Sostenibilidad”, el cual
                        lleva a un apartado donde se proporcionan detalles sobre los esfuerzos de la
                        empresa para crear espacios de trabajo sostenibles, aumentar la eficiencia energética de sus
                        centros de datos y
                        compartir las mejores prácticas para crear un modelo de negocio ambientalmente
                        amigable.</p>


                    <p>Referencias Bibliográficas
                        <ul>
                            <li>Payton, S. (2020). The world’s top companies for online IR. Octubre 15, 2020. <a
                                href="https://www.irmagazine.com/technology-social-media/worlds-top-companies-online-ir"
                                target="_blank"> IR Magazine</a>.
                            </li>
                            <li>Payton, S. (2018). Eight tips from the world’s best IR websites. Junio 15, 2018. <a
                                href="https://www.irmagazine.com/technology-social-media/eight-tips-worlds-best-ir-websites"
                                target="_blank"> IR Magazine</a>.
                            </li>
                            <li>Q4. (2017). IR Website Best Practices, North America & Europe (<a
                                href="https://irsociety.org.uk/files/Q4_Whitepaper_-_2017_IR_Website_Best_Practices.pdf"
                                target="_blank"> White Paper</a>). Febrero 2017.
                            </li>
                            <li>Doss. S. (2016). Why every public company needs an IR website. Noviembre 29, 2016. <a
                                href="http://www.suttonintegrated.com/sutton-integrated-company-ir-websites.html"
                                target="_blank"> Sutton Integrated Solutions</a>.
                            </li>
                        </ul>
                    </p>

                </div>
            </div>
        </Single>
    )
}

export default Detail
